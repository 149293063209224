import { default as activateGOicVs1VjiMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/auth/activate.vue?macro=true";
import { default as indexxda6SAabzmMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/auth/index.vue?macro=true";
import { default as restorea4YNIS8KAgMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/auth/organisations/restore.vue?macro=true";
import { default as register_45completebeJJS1ZMMiMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/auth/register-complete.vue?macro=true";
import { default as registerdTunpRtswfMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/auth/register.vue?macro=true";
import { default as _91token_93Msa5axnXvDMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/boards/[token].vue?macro=true";
import { default as indexO41SNggdnkMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/buzzer/index.vue?macro=true";
import { default as _91id_93JfHscJVqRtMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/dashboards/[id].vue?macro=true";
import { default as indexFo8klsWfAhMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/dashboards/index.vue?macro=true";
import { default as _91token_931rn4NXRFdzMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/dashboards/token/[token].vue?macro=true";
import { default as tokenfRWJ5aPIcwMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/dashboards/token.vue?macro=true";
import { default as demoBxBBYyesjyMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/demo.vue?macro=true";
import { default as indexSNz8IwJo9KMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/events/index.vue?macro=true";
import { default as impressumJoII9qO2vSMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/impressum.vue?macro=true";
import { default as indexGRzB4ewPENMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/index.vue?macro=true";
import { default as _91id_93GkiI0PyL5uMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/leaderboards/[id].vue?macro=true";
import { default as indexAQXdpogSMmMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/leaderboards/index.vue?macro=true";
import { default as _91id_93ZTxpSy1Z9tMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/deals/[id].vue?macro=true";
import { default as index9T5BgMYgVWMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/deals/index.vue?macro=true";
import { default as HSLtoRGBV2MinLqlUnMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/devices/HSLtoRGB.js?macro=true";
import { default as RGBtoHSLQc0db2kKKiMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/devices/RGBtoHSL.js?macro=true";
import { default as _91id_93KLJuDXlzcUMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/devices/[id].vue?macro=true";
import { default as _91id_93PH55dOnv1nMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/devices/devicegroup/[id].vue?macro=true";
import { default as indexYgNwRVMm87Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/devices/index.vue?macro=true";
import { default as _91id_93IY0xuxk1PcMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/eventTypes/[id].vue?macro=true";
import { default as indexiopnFyVEa0Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/eventTypes/index.vue?macro=true";
import { default as _91id_93R0CD5F1BZJMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/goals/[id].vue?macro=true";
import { default as indexBIqWUgPZAaMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/goals/index.vue?macro=true";
import { default as _91id_93LxRDUMK7OUMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/groups/[id].vue?macro=true";
import { default as indexZ0BgohAHXHMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/groups/index.vue?macro=true";
import { default as indexlsObkpg6LGMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/settings/index.vue?macro=true";
import { default as indexCOW24IAptfMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/subscriptions/index.vue?macro=true";
import { default as _91id_93dlnHWsLuRtMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/users/[id].vue?macro=true";
import { default as indexWRfCffNd0AMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/users/index.vue?macro=true";
import { default as _91id_93bPr4dzZBHzMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/sounds/[id].vue?macro=true";
import { default as entries7sMNh7EyyIMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/sounds/index/entries.vue?macro=true";
import { default as playlistsvYoZt8sz45Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/sounds/index/playlists.vue?macro=true";
import { default as indexFFafRP2pRLMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/sounds/index.vue?macro=true";
import { default as indexXkTRzXW6xcMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/devices/index.vue?macro=true";
import { default as _91id_93qjG1zcwz6OMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/firmware/[id].vue?macro=true";
import { default as indexJPlSNbNAdYMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/firmware/index.vue?macro=true";
import { default as _91id_93xfKqOkPe4xMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/hardware/[id].vue?macro=true";
import { default as indexGoQij79u94Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/hardware/index.vue?macro=true";
import { default as indexdcFaStz5uZMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/logs/index.vue?macro=true";
import { default as _91id_93Yp9FGdoIOvMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/notificationTemplates/[id].vue?macro=true";
import { default as indexnnALPwTWjyMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/notificationTemplates/index.vue?macro=true";
import { default as _91id_933CjiE58gr3Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/organisations/[id].vue?macro=true";
import { default as indexXfeNpu9xlZMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/organisations/index.vue?macro=true";
import { default as _91id_93V1dvhSj2YZMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/plans/[id].vue?macro=true";
import { default as indexPWerC9Dfl0Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/plans/index.vue?macro=true";
import { default as releaselogndHUFRa9yOMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/releaselog.vue?macro=true";
import { default as toolsneBc03U0JnMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/tools.vue?macro=true";
import { default as _91id_93egsLHMwBgjMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/users/[id].vue?macro=true";
import { default as index0FJ1EZObK6Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/users/index.vue?macro=true";
export default [
  {
    name: activateGOicVs1VjiMeta?.name ?? "auth-activate",
    path: activateGOicVs1VjiMeta?.path ?? "/auth/activate",
    meta: activateGOicVs1VjiMeta || {},
    alias: activateGOicVs1VjiMeta?.alias || [],
    redirect: activateGOicVs1VjiMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/auth/activate.vue").then(m => m.default || m)
  },
  {
    name: indexxda6SAabzmMeta?.name ?? "auth",
    path: indexxda6SAabzmMeta?.path ?? "/auth",
    meta: indexxda6SAabzmMeta || {},
    alias: indexxda6SAabzmMeta?.alias || [],
    redirect: indexxda6SAabzmMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: restorea4YNIS8KAgMeta?.name ?? "auth-organisations-restore",
    path: restorea4YNIS8KAgMeta?.path ?? "/auth/organisations/restore",
    meta: restorea4YNIS8KAgMeta || {},
    alias: restorea4YNIS8KAgMeta?.alias || [],
    redirect: restorea4YNIS8KAgMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/auth/organisations/restore.vue").then(m => m.default || m)
  },
  {
    name: register_45completebeJJS1ZMMiMeta?.name ?? "auth-register-complete",
    path: register_45completebeJJS1ZMMiMeta?.path ?? "/auth/register-complete",
    meta: register_45completebeJJS1ZMMiMeta || {},
    alias: register_45completebeJJS1ZMMiMeta?.alias || [],
    redirect: register_45completebeJJS1ZMMiMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/auth/register-complete.vue").then(m => m.default || m)
  },
  {
    name: registerdTunpRtswfMeta?.name ?? "auth-register",
    path: registerdTunpRtswfMeta?.path ?? "/auth/register",
    meta: registerdTunpRtswfMeta || {},
    alias: registerdTunpRtswfMeta?.alias || [],
    redirect: registerdTunpRtswfMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: _91token_93Msa5axnXvDMeta?.name ?? "boards-token",
    path: _91token_93Msa5axnXvDMeta?.path ?? "/boards/:token()",
    meta: _91token_93Msa5axnXvDMeta || {},
    alias: _91token_93Msa5axnXvDMeta?.alias || [],
    redirect: _91token_93Msa5axnXvDMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/boards/[token].vue").then(m => m.default || m)
  },
  {
    name: indexO41SNggdnkMeta?.name ?? "buzzer",
    path: indexO41SNggdnkMeta?.path ?? "/buzzer",
    meta: indexO41SNggdnkMeta || {},
    alias: indexO41SNggdnkMeta?.alias || [],
    redirect: indexO41SNggdnkMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/buzzer/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93JfHscJVqRtMeta?.name ?? "dashboards-id",
    path: _91id_93JfHscJVqRtMeta?.path ?? "/dashboards/:id()",
    meta: _91id_93JfHscJVqRtMeta || {},
    alias: _91id_93JfHscJVqRtMeta?.alias || [],
    redirect: _91id_93JfHscJVqRtMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/dashboards/[id].vue").then(m => m.default || m)
  },
  {
    name: indexFo8klsWfAhMeta?.name ?? "dashboards",
    path: indexFo8klsWfAhMeta?.path ?? "/dashboards",
    meta: indexFo8klsWfAhMeta || {},
    alias: indexFo8klsWfAhMeta?.alias || [],
    redirect: indexFo8klsWfAhMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/dashboards/index.vue").then(m => m.default || m)
  },
  {
    name: tokenfRWJ5aPIcwMeta?.name ?? "dashboards-token",
    path: tokenfRWJ5aPIcwMeta?.path ?? "/dashboards/token",
    children: [
  {
    name: _91token_931rn4NXRFdzMeta?.name ?? "dashboards-token-token",
    path: _91token_931rn4NXRFdzMeta?.path ?? ":token()",
    meta: _91token_931rn4NXRFdzMeta || {},
    alias: _91token_931rn4NXRFdzMeta?.alias || [],
    redirect: _91token_931rn4NXRFdzMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/dashboards/token/[token].vue").then(m => m.default || m)
  }
],
    meta: tokenfRWJ5aPIcwMeta || {},
    alias: tokenfRWJ5aPIcwMeta?.alias || [],
    redirect: tokenfRWJ5aPIcwMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/dashboards/token.vue").then(m => m.default || m)
  },
  {
    name: demoBxBBYyesjyMeta?.name ?? "demo",
    path: demoBxBBYyesjyMeta?.path ?? "/demo",
    meta: demoBxBBYyesjyMeta || {},
    alias: demoBxBBYyesjyMeta?.alias || [],
    redirect: demoBxBBYyesjyMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/demo.vue").then(m => m.default || m)
  },
  {
    name: indexSNz8IwJo9KMeta?.name ?? "events",
    path: indexSNz8IwJo9KMeta?.path ?? "/events",
    meta: indexSNz8IwJo9KMeta || {},
    alias: indexSNz8IwJo9KMeta?.alias || [],
    redirect: indexSNz8IwJo9KMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: impressumJoII9qO2vSMeta?.name ?? "impressum",
    path: impressumJoII9qO2vSMeta?.path ?? "/impressum",
    meta: impressumJoII9qO2vSMeta || {},
    alias: impressumJoII9qO2vSMeta?.alias || [],
    redirect: impressumJoII9qO2vSMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: indexGRzB4ewPENMeta?.name ?? "index",
    path: indexGRzB4ewPENMeta?.path ?? "/",
    meta: indexGRzB4ewPENMeta || {},
    alias: indexGRzB4ewPENMeta?.alias || [],
    redirect: indexGRzB4ewPENMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93GkiI0PyL5uMeta?.name ?? "leaderboards-id",
    path: _91id_93GkiI0PyL5uMeta?.path ?? "/leaderboards/:id()",
    meta: _91id_93GkiI0PyL5uMeta || {},
    alias: _91id_93GkiI0PyL5uMeta?.alias || [],
    redirect: _91id_93GkiI0PyL5uMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/leaderboards/[id].vue").then(m => m.default || m)
  },
  {
    name: indexAQXdpogSMmMeta?.name ?? "leaderboards",
    path: indexAQXdpogSMmMeta?.path ?? "/leaderboards",
    meta: indexAQXdpogSMmMeta || {},
    alias: indexAQXdpogSMmMeta?.alias || [],
    redirect: indexAQXdpogSMmMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/leaderboards/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ZTxpSy1Z9tMeta?.name ?? "organisation-deals-id",
    path: _91id_93ZTxpSy1Z9tMeta?.path ?? "/organisation/deals/:id()",
    meta: _91id_93ZTxpSy1Z9tMeta || {},
    alias: _91id_93ZTxpSy1Z9tMeta?.alias || [],
    redirect: _91id_93ZTxpSy1Z9tMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/deals/[id].vue").then(m => m.default || m)
  },
  {
    name: index9T5BgMYgVWMeta?.name ?? "organisation-deals",
    path: index9T5BgMYgVWMeta?.path ?? "/organisation/deals",
    meta: index9T5BgMYgVWMeta || {},
    alias: index9T5BgMYgVWMeta?.alias || [],
    redirect: index9T5BgMYgVWMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/deals/index.vue").then(m => m.default || m)
  },
  {
    name: HSLtoRGBV2MinLqlUnMeta?.name ?? "organisation-devices-HSLtoRGB",
    path: HSLtoRGBV2MinLqlUnMeta?.path ?? "/organisation/devices/HSLtoRGB",
    meta: HSLtoRGBV2MinLqlUnMeta || {},
    alias: HSLtoRGBV2MinLqlUnMeta?.alias || [],
    redirect: HSLtoRGBV2MinLqlUnMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/devices/HSLtoRGB.js").then(m => m.default || m)
  },
  {
    name: RGBtoHSLQc0db2kKKiMeta?.name ?? "organisation-devices-RGBtoHSL",
    path: RGBtoHSLQc0db2kKKiMeta?.path ?? "/organisation/devices/RGBtoHSL",
    meta: RGBtoHSLQc0db2kKKiMeta || {},
    alias: RGBtoHSLQc0db2kKKiMeta?.alias || [],
    redirect: RGBtoHSLQc0db2kKKiMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/devices/RGBtoHSL.js").then(m => m.default || m)
  },
  {
    name: _91id_93KLJuDXlzcUMeta?.name ?? "organisation-devices-id",
    path: _91id_93KLJuDXlzcUMeta?.path ?? "/organisation/devices/:id()",
    meta: _91id_93KLJuDXlzcUMeta || {},
    alias: _91id_93KLJuDXlzcUMeta?.alias || [],
    redirect: _91id_93KLJuDXlzcUMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/devices/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93PH55dOnv1nMeta?.name ?? "organisation-devices-devicegroup-id",
    path: _91id_93PH55dOnv1nMeta?.path ?? "/organisation/devices/devicegroup/:id()",
    meta: _91id_93PH55dOnv1nMeta || {},
    alias: _91id_93PH55dOnv1nMeta?.alias || [],
    redirect: _91id_93PH55dOnv1nMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/devices/devicegroup/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYgNwRVMm87Meta?.name ?? "organisation-devices",
    path: indexYgNwRVMm87Meta?.path ?? "/organisation/devices",
    meta: indexYgNwRVMm87Meta || {},
    alias: indexYgNwRVMm87Meta?.alias || [],
    redirect: indexYgNwRVMm87Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/devices/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93IY0xuxk1PcMeta?.name ?? "organisation-eventTypes-id",
    path: _91id_93IY0xuxk1PcMeta?.path ?? "/organisation/eventTypes/:id()",
    meta: _91id_93IY0xuxk1PcMeta || {},
    alias: _91id_93IY0xuxk1PcMeta?.alias || [],
    redirect: _91id_93IY0xuxk1PcMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/eventTypes/[id].vue").then(m => m.default || m)
  },
  {
    name: indexiopnFyVEa0Meta?.name ?? "organisation-eventTypes",
    path: indexiopnFyVEa0Meta?.path ?? "/organisation/eventTypes",
    meta: indexiopnFyVEa0Meta || {},
    alias: indexiopnFyVEa0Meta?.alias || [],
    redirect: indexiopnFyVEa0Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/eventTypes/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93R0CD5F1BZJMeta?.name ?? "organisation-goals-id",
    path: _91id_93R0CD5F1BZJMeta?.path ?? "/organisation/goals/:id()",
    meta: _91id_93R0CD5F1BZJMeta || {},
    alias: _91id_93R0CD5F1BZJMeta?.alias || [],
    redirect: _91id_93R0CD5F1BZJMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/goals/[id].vue").then(m => m.default || m)
  },
  {
    name: indexBIqWUgPZAaMeta?.name ?? "organisation-goals",
    path: indexBIqWUgPZAaMeta?.path ?? "/organisation/goals",
    meta: indexBIqWUgPZAaMeta || {},
    alias: indexBIqWUgPZAaMeta?.alias || [],
    redirect: indexBIqWUgPZAaMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/goals/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93LxRDUMK7OUMeta?.name ?? "organisation-groups-id",
    path: _91id_93LxRDUMK7OUMeta?.path ?? "/organisation/groups/:id()",
    meta: _91id_93LxRDUMK7OUMeta || {},
    alias: _91id_93LxRDUMK7OUMeta?.alias || [],
    redirect: _91id_93LxRDUMK7OUMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/groups/[id].vue").then(m => m.default || m)
  },
  {
    name: indexZ0BgohAHXHMeta?.name ?? "organisation-groups",
    path: indexZ0BgohAHXHMeta?.path ?? "/organisation/groups",
    meta: indexZ0BgohAHXHMeta || {},
    alias: indexZ0BgohAHXHMeta?.alias || [],
    redirect: indexZ0BgohAHXHMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/groups/index.vue").then(m => m.default || m)
  },
  {
    name: indexlsObkpg6LGMeta?.name ?? "organisation-settings",
    path: indexlsObkpg6LGMeta?.path ?? "/organisation/settings",
    meta: indexlsObkpg6LGMeta || {},
    alias: indexlsObkpg6LGMeta?.alias || [],
    redirect: indexlsObkpg6LGMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexCOW24IAptfMeta?.name ?? "organisation-subscriptions",
    path: indexCOW24IAptfMeta?.path ?? "/organisation/subscriptions",
    meta: indexCOW24IAptfMeta || {},
    alias: indexCOW24IAptfMeta?.alias || [],
    redirect: indexCOW24IAptfMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93dlnHWsLuRtMeta?.name ?? "organisation-users-id",
    path: _91id_93dlnHWsLuRtMeta?.path ?? "/organisation/users/:id()",
    meta: _91id_93dlnHWsLuRtMeta || {},
    alias: _91id_93dlnHWsLuRtMeta?.alias || [],
    redirect: _91id_93dlnHWsLuRtMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indexWRfCffNd0AMeta?.name ?? "organisation-users",
    path: indexWRfCffNd0AMeta?.path ?? "/organisation/users",
    meta: indexWRfCffNd0AMeta || {},
    alias: indexWRfCffNd0AMeta?.alias || [],
    redirect: indexWRfCffNd0AMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/organisation/users/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93bPr4dzZBHzMeta?.name ?? "sounds-id",
    path: _91id_93bPr4dzZBHzMeta?.path ?? "/sounds/:id()",
    meta: _91id_93bPr4dzZBHzMeta || {},
    alias: _91id_93bPr4dzZBHzMeta?.alias || [],
    redirect: _91id_93bPr4dzZBHzMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/sounds/[id].vue").then(m => m.default || m)
  },
  {
    name: indexFFafRP2pRLMeta?.name ?? "sounds",
    path: indexFFafRP2pRLMeta?.path ?? "/sounds",
    children: [
  {
    name: entries7sMNh7EyyIMeta?.name ?? "sounds-index-entries",
    path: entries7sMNh7EyyIMeta?.path ?? "entries",
    meta: entries7sMNh7EyyIMeta || {},
    alias: entries7sMNh7EyyIMeta?.alias || [],
    redirect: entries7sMNh7EyyIMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/sounds/index/entries.vue").then(m => m.default || m)
  },
  {
    name: playlistsvYoZt8sz45Meta?.name ?? "sounds-index-playlists",
    path: playlistsvYoZt8sz45Meta?.path ?? "playlists",
    meta: playlistsvYoZt8sz45Meta || {},
    alias: playlistsvYoZt8sz45Meta?.alias || [],
    redirect: playlistsvYoZt8sz45Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/sounds/index/playlists.vue").then(m => m.default || m)
  }
],
    meta: indexFFafRP2pRLMeta || {},
    alias: indexFFafRP2pRLMeta?.alias || [],
    redirect: indexFFafRP2pRLMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/sounds/index.vue").then(m => m.default || m)
  },
  {
    name: indexXkTRzXW6xcMeta?.name ?? "system-devices",
    path: indexXkTRzXW6xcMeta?.path ?? "/system/devices",
    meta: indexXkTRzXW6xcMeta || {},
    alias: indexXkTRzXW6xcMeta?.alias || [],
    redirect: indexXkTRzXW6xcMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/devices/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qjG1zcwz6OMeta?.name ?? "system-firmware-id",
    path: _91id_93qjG1zcwz6OMeta?.path ?? "/system/firmware/:id()",
    meta: _91id_93qjG1zcwz6OMeta || {},
    alias: _91id_93qjG1zcwz6OMeta?.alias || [],
    redirect: _91id_93qjG1zcwz6OMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/firmware/[id].vue").then(m => m.default || m)
  },
  {
    name: indexJPlSNbNAdYMeta?.name ?? "system-firmware",
    path: indexJPlSNbNAdYMeta?.path ?? "/system/firmware",
    meta: indexJPlSNbNAdYMeta || {},
    alias: indexJPlSNbNAdYMeta?.alias || [],
    redirect: indexJPlSNbNAdYMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/firmware/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93xfKqOkPe4xMeta?.name ?? "system-hardware-id",
    path: _91id_93xfKqOkPe4xMeta?.path ?? "/system/hardware/:id()",
    meta: _91id_93xfKqOkPe4xMeta || {},
    alias: _91id_93xfKqOkPe4xMeta?.alias || [],
    redirect: _91id_93xfKqOkPe4xMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/hardware/[id].vue").then(m => m.default || m)
  },
  {
    name: indexGoQij79u94Meta?.name ?? "system-hardware",
    path: indexGoQij79u94Meta?.path ?? "/system/hardware",
    meta: indexGoQij79u94Meta || {},
    alias: indexGoQij79u94Meta?.alias || [],
    redirect: indexGoQij79u94Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/hardware/index.vue").then(m => m.default || m)
  },
  {
    name: indexdcFaStz5uZMeta?.name ?? "system-logs",
    path: indexdcFaStz5uZMeta?.path ?? "/system/logs",
    meta: indexdcFaStz5uZMeta || {},
    alias: indexdcFaStz5uZMeta?.alias || [],
    redirect: indexdcFaStz5uZMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/logs/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Yp9FGdoIOvMeta?.name ?? "system-notificationTemplates-id",
    path: _91id_93Yp9FGdoIOvMeta?.path ?? "/system/notificationTemplates/:id()",
    meta: _91id_93Yp9FGdoIOvMeta || {},
    alias: _91id_93Yp9FGdoIOvMeta?.alias || [],
    redirect: _91id_93Yp9FGdoIOvMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/notificationTemplates/[id].vue").then(m => m.default || m)
  },
  {
    name: indexnnALPwTWjyMeta?.name ?? "system-notificationTemplates",
    path: indexnnALPwTWjyMeta?.path ?? "/system/notificationTemplates",
    meta: indexnnALPwTWjyMeta || {},
    alias: indexnnALPwTWjyMeta?.alias || [],
    redirect: indexnnALPwTWjyMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/notificationTemplates/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_933CjiE58gr3Meta?.name ?? "system-organisations-id",
    path: _91id_933CjiE58gr3Meta?.path ?? "/system/organisations/:id()",
    meta: _91id_933CjiE58gr3Meta || {},
    alias: _91id_933CjiE58gr3Meta?.alias || [],
    redirect: _91id_933CjiE58gr3Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/organisations/[id].vue").then(m => m.default || m)
  },
  {
    name: indexXfeNpu9xlZMeta?.name ?? "system-organisations",
    path: indexXfeNpu9xlZMeta?.path ?? "/system/organisations",
    meta: indexXfeNpu9xlZMeta || {},
    alias: indexXfeNpu9xlZMeta?.alias || [],
    redirect: indexXfeNpu9xlZMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93V1dvhSj2YZMeta?.name ?? "system-plans-id",
    path: _91id_93V1dvhSj2YZMeta?.path ?? "/system/plans/:id()",
    meta: _91id_93V1dvhSj2YZMeta || {},
    alias: _91id_93V1dvhSj2YZMeta?.alias || [],
    redirect: _91id_93V1dvhSj2YZMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/plans/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPWerC9Dfl0Meta?.name ?? "system-plans",
    path: indexPWerC9Dfl0Meta?.path ?? "/system/plans",
    meta: indexPWerC9Dfl0Meta || {},
    alias: indexPWerC9Dfl0Meta?.alias || [],
    redirect: indexPWerC9Dfl0Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/plans/index.vue").then(m => m.default || m)
  },
  {
    name: releaselogndHUFRa9yOMeta?.name ?? "system-releaselog",
    path: releaselogndHUFRa9yOMeta?.path ?? "/system/releaselog",
    meta: releaselogndHUFRa9yOMeta || {},
    alias: releaselogndHUFRa9yOMeta?.alias || [],
    redirect: releaselogndHUFRa9yOMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/releaselog.vue").then(m => m.default || m)
  },
  {
    name: toolsneBc03U0JnMeta?.name ?? "system-tools",
    path: toolsneBc03U0JnMeta?.path ?? "/system/tools",
    meta: toolsneBc03U0JnMeta || {},
    alias: toolsneBc03U0JnMeta?.alias || [],
    redirect: toolsneBc03U0JnMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/tools.vue").then(m => m.default || m)
  },
  {
    name: _91id_93egsLHMwBgjMeta?.name ?? "system-users-id",
    path: _91id_93egsLHMwBgjMeta?.path ?? "/system/users/:id()",
    meta: _91id_93egsLHMwBgjMeta || {},
    alias: _91id_93egsLHMwBgjMeta?.alias || [],
    redirect: _91id_93egsLHMwBgjMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/users/[id].vue").then(m => m.default || m)
  },
  {
    name: index0FJ1EZObK6Meta?.name ?? "system-users",
    path: index0FJ1EZObK6Meta?.path ?? "/system/users",
    meta: index0FJ1EZObK6Meta || {},
    alias: index0FJ1EZObK6Meta?.alias || [],
    redirect: index0FJ1EZObK6Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20241106105100/client/pages/system/users/index.vue").then(m => m.default || m)
  }
]